import React, { useState } from 'react';
import { lighten } from 'polished';
import { css } from '@emotion/react';

import { colors } from '../styles/colors';
import config from '../website-config';

export const SubscriptionForm = () => {

  const [status, setStatus] = useState("");
  const YOUR_FORM_URL = `https://app.convertkit.com/forms/${config.convertKitFormId}/subscriptions`;

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData(e.target);

    try {
      const response = await fetch(
        YOUR_FORM_URL,
        {
          method: 'post',
          body: data,
          headers: {
            accept: 'application/json',
          },
        }
      );

      const json = await response.json();

      if (json.status === 'success') {
        setStatus('SUCCESS');
        return;
      }

      setStatus('ERROR');
    } catch (err) {
      setStatus('ERROR');
    }
  };

  return (
    <div css={SubscriptionFormContainer}>
      <p css={SubscriptionFormTitle}>Do you want to be updated once-a-week with new posts, {"\n"} travel tips and exclusive content by email?</p>
      <form
        action={YOUR_FORM_URL}
        method="post"
        onSubmit={handleSubmit}
      >
        <input
          type="email"
          aria-label="Your email"
          name="email_address"
          placeholder="Your email address"
          required
          css={SubscriptionFormInput}
        />
        <button
          type="submit"
          css={SubscriptionFormButton}
        >
          <span>Subscribe</span>
        </button>

        {status === 'SUCCESS' && <p>Please confirm your subscription.</p>}
        {status === 'ERROR' && <p>Oops, try again.</p>}
      </form>
      <p css={SubscriptionFormDisclaimer}>No spam, sales, or ads. Unsubscribe as you wish.</p>
    </div>
  );
};

const SubscriptionFormContainer = css`
  position: relative;
  text-align: center;
  display: block;
  align-items: center;
  justify-content: center;
  max-width: 700px;
  margin: 0 auto;
`;

const SubscriptionFormTitle = css`
  font-size: 2.1rem;
  white-space: pre-line;
`;

const SubscriptionFormDisclaimer = css`
  font-size: 1.3rem;
`;

const SubscriptionFormButton = css`
  border-radius: 4px;
  border: 0px;
  background-color: ${(colors.blue)};
  text-align: center;
  padding: 6px;
  font-size: 1.7rem;
`;

const SubscriptionFormInput = css`
  border-radius: 4px;
  border: 0px;
  background: #ffffff;
  color: ${(colors.darkgrey)};
  margin: 0 10px 15px 10px;
  width: 400px;
  text-align: left;
  padding: 8px;
  font-size: 1.5rem;
`;